@import '~react-image-gallery/styles/css/image-gallery.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070B14;
}
input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0.6;
  filter: invert(0.5);
}
.scroll::-webkit-scrollbar {
  width: 0.1px; /* Set the width of the scrollbar */
}
.scroll-width::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
}
/* Add this CSS in a separate stylesheet or within a style tag in your component */
@media (max-width: 600px) {
  .sidebar {
    display: none;
  }
}
.bgleftshade {
  background-image: url('./assets/Images/2.svg');
  background-position: left;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  /* background-size: cover; */
}
.bgrightshade {
  background-image: url('./assets/Images/4.svg');
  background-position: right;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}
/* Add hover effect to the Grid container */
.grid-container:hover .icon-wrapper {
  transform: scale(1.2); /* Scale the icon on hover */
}

/* Add hover effect to the label */
.grid-container:hover .icon-label::before {
  width: 100%;
  transition: width 0.3s ease;
}

.bgrightshade2 {
  background-image: url('./assets/Images/16.svg');
  background-position: right;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}
input[type='date']::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.recharts-legend-item-text {
  padding-top: 40px !important;
  padding-left: 10px !important;
}
.activetask {
  cursor: pointer;
  transition: all 0.1 ease-in-out;
}
.activetask {
  cursor: pointer;
}
.activetask:active {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.activetask:hover {
  transition: all 0.1 ease-in-out;
  translate: 4px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.bgColorFilter {
  background-color: #111111 !important;
  color: white !important;
  min-height: 100% !important;
}
.bgColorFilterLight {
  background-color: white !important;
  color: black !important;
  min-height: 100% !important;
}
input[data-autocompleted] {
  background-color: transparent !important;
}
.css-1autqdf-MuiPaper-root-MuiDrawer-paper {
  background: transparent !important;
}
.bgColorFilter:hover {
  background-color: white !important;
  color: #111111 !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}
.css-1d04vmd-MuiPaper-root-MuiAppBar-root,
.css-13didvw-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}

.modal-content-wrapper {
  /* opacity: 0; */
  transition: opacity 0.5s ease-in-out;
}

/* Global color */
.colorGreen {
  color: #00cb45 !important;
}

.colorRed {
  color: #db0000 !important;
}

.colorBlue {
  color: #599bf7 !important;
}

.colorGray {
  color: #8a8a8a;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: 'transparent' !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-left: 0px !important;
  padding-right: 32px;
}

.text-error {
  color: #db0000 !important;
  font-size: 12px;
}

.App {
  background-color: #171717;
}

fieldset {
  border-width: 0px !important;
  border-color: transparent !important;
}

body {
  overscroll-behavior: none;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

/* .Mui-selected {
    background-color: #939393 !important;
} */
